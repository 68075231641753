import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import authenticated from 'mobile-web/decorators/authenticated';
import { PreviousRoute } from 'mobile-web/lib/routing';
import OrderModel from 'mobile-web/models/order';
import OrderFailureModel from 'mobile-web/models/order-failure';

export type Model = { order: OrderModel; failure: OrderFailureModel };

@authenticated
export default class OrderSummaryRoute extends Route {
  @service store!: DS.Store;

  previousRoute: PreviousRoute = { label: 'Recent Orders', route: 'recent-orders', models: [] };

  model(params: { order_id: string }): RSVP.Promise<Model> {
    return this.store.findRecord('order', params.order_id, { reload: true }).then(order => {
      const failure = order.get('orderFailure');
      return RSVP.hash({ order, failure });
    });
  }
}
