export default {
  "root": "_root_5ondzy",
  "allTotal": "_allTotal_5ondzy",
  "headingSection": "_headingSection_5ondzy",
  "subsection": "_subsection_5ondzy",
  "button": "_button_5ondzy",
  "contentsRow": "_contentsRow_5ondzy",
  "sentimentSurveyModal": "_sentimentSurveyModal_5ondzy",
  "sentimentSurveyModalSection": "_sentimentSurveyModalSection_5ondzy",
  "sentimentSurveyModalContentButtonsContainer": "_sentimentSurveyModalContentButtonsContainer_5ondzy",
  "sentimentSurveyButton": "_sentimentSurveyButton_5ondzy"
};
