import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import LoginProvider from 'mobile-web/lib/login-provider';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onClick: Action;
  provider: LoginProvider;

  // Optional arguments
  buttonType?: 'checkout' | 'signin';
}

interface Signature {
  Element: HTMLButtonElement;

  Args: Args;
}

export default class LoginButton extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get buttonLabel(): string {
    return this.args.buttonType === 'checkout'
      ? this.intl.t('mwc.oloAuth.loginButton.checkoutLabel')
      : this.intl.t('mwc.oloAuth.loginButton.signInLabel');
  }

  get buttonText(): string {
    return this.args.buttonType === 'checkout'
      ? this.intl.t('mwc.oloAuth.loginButton.checkout')
      : this.intl.t('mwc.oloAuth.loginButton.signIn');
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  handleClick() {
    this.args.onClick(this.args.provider);
    this.trackClick();
  }

  @action
  trackClick() {
    // OLO-73662 Borderless AB Testing
    this.analytics.trackEvent(AnalyticsEvents.OloAuthSignInWithOloButtonClicked, undefined, {
      bucket: 'all',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OloAuth::LoginButton': typeof LoginButton;
  }
}
