export default {
  "root": "_root_p976je",
  "bannerContainer": "_bannerContainer_p976je",
  "bannerText": "_bannerText_p976je",
  "icon": "_icon_p976je",
  "handoffSummaryNew": "_handoffSummaryNew_p976je",
  "handoffMessageNew": "_handoffMessageNew_p976je",
  "minorText": "_minorText_p976je",
  "sectionContainer": "_sectionContainer_p976je",
  "section": "_section_p976je",
  "orderSummarySection": "_orderSummarySection_p976je _section_p976je",
  "simpleSection": "_simpleSection_p976je _section_p976je",
  "buttonItem": "_buttonItem_p976je",
  "reorderButton": "_reorderButton_p976je",
  "buttonContainer": "_buttonContainer_p976je",
  "thanksMessageDetails": "_thanksMessageDetails_p976je"
};
