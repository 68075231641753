import { inject as service } from '@ember/service';
import DS from 'ember-data';

import BasketService from 'mobile-web/services/basket';

import UpsellItemModel from './upsell-item';

export default class UpsellGroupModel extends DS.Model {
  @service basket!: BasketService;

  @DS.attr('string')
  title!: string;

  @DS.hasMany('upsell-item', { async: false })
  items!: DS.ManyArray<UpsellItemModel>;
}
