import Component from '@glimmer/component';

import LoginProvider from 'mobile-web/lib/login-provider';

import style from './index.m.scss';

interface Args {
  // Required arguments
  provider: LoginProvider;
  onClick: Action;

  // Optional arguments
}

interface Signature {
  Element: HTMLButtonElement;

  Args: Args;
}

export default class FacebookLoginButton extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FacebookLoginButton: typeof FacebookLoginButton;
  }
}
